.header {
  height: fit-content;
  padding: var(--mantine-spacing-lg);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);

  @media (max-width: $mantine-breakpoint-sm) {
    height: rem(20px);
    width: 'auto';
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.subLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  }
}

.dropdownFooter {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}

.header {
  padding-top: var(--mantine-spacing-sm);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-2), transparent);
  margin-bottom: rem(120px);
}

.mainSection {
  padding-bottom: var(--mantine-spacing-sm);
}

.user {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  transition: background-color 100ms ease;

  &:hover {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
  }

  @media (max-width: $mantine-breakpoint-xs) {
    display: none;
  }
}

.userActive {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
}

.tabsList {
  &::before {
    display: none;
  }
}

.tab {
  font-weight: 500;
  height: rem(38);
  background-color: transparent;
  position: relative;
  bottom: -1px;

  &:hover {
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  &[data-active] {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
    border-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-7));
    border-bottom-color: transparent;
  }
}


@media only screen and (max-width: 600px) {
  .logo{
      height: 50px !important;
      width: auto;
    }

    .header{
      padding: var(--mantine-spacing-sm);
    }
}