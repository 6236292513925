html,body{
  overflow-x: hidden;
  background-color: black
}

body{
min-height: 100vh;
}

#root{
  background-color: #121212;
}